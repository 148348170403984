<template>
  <div>update instance page</div>
</template>

<script>
  export default {
    name: 'InstancesUpdate',
  }
</script>

<style></style>
