<template>
  <main class="row" v-loading="loading">
    <div class="card">
      <div class="card-header pb-0">
        <div class="col-auto ms-auto text-end mt-n1">
          <el-select class="border-0" v-model="pageSize" placeholder="Select" size="mini">
            <el-option
              v-for="item in pageOptions"
              :key="item"
              :label="item.type"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <table class="table table-striped table-hover mb-5 mt-2" style="width: 100%">
        <thead>
          <tr>
            <th>
              <a v-on:click="handleSortIconClick('name')" v-if="nameSortIcon % 3 === 0">
                Name <i class="fas fa-sort" />
              </a>
              <a v-on:click="handleSortIconClick('name')" v-if="nameSortIcon % 3 === 1">
                Name <i class="fas fa-sort-up" />
              </a>
              <a v-on:click="handleSortIconClick('name')" v-if="nameSortIcon % 3 === 2">
                Name <i class="fas fa-sort-down" />
              </a>
            </th>
            <th class="d-none d-xxl-table-cell">
              <a v-on:click="handleSortIconClick('domain')" v-if="domainSortIcon % 3 === 0">
                Domain <i class="fas fa-sort" />
              </a>
              <a v-on:click="handleSortIconClick('domain')" v-if="domainSortIcon % 3 === 1">
                Domain <i class="fas fa-sort-up" />
              </a>
              <a v-on:click="handleSortIconClick('domain')" v-if="domainSortIcon % 3 === 2">
                Domain <i class="fas fa-sort-down" />
              </a>
            </th>
            <th class="d-none d-xl-table-cell">
              <a v-on:click="handleSortIconClick('type')" v-if="typeSortIcon % 3 === 0">
                Type <i class="fas fa-sort" />
              </a>
              <a v-on:click="handleSortIconClick('type')" v-if="typeSortIcon % 3 === 1">
                Type <i class="fas fa-sort-up" />
              </a>
              <a v-on:click="handleSortIconClick('type')" v-if="typeSortIcon % 3 === 2">
                Type <i class="fas fa-sort-down" />
              </a>
            </th>
            <th class="d-xl-table-cell">Details</th>
            <th class="d-xl-table-cell text-end">Disable</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in paginatedTableData" :key="item.id">
            <td height="45">
              <div class="d-flex">
                <div class="flex-shrink-0">
                  <div class="bg-light rounded-2">
                    <img
                      class="logo-img"
                      :src="
                        `https://ndmglobal.sfo2.digitaloceanspaces.com/DocQ_email/${item.domain}.png`
                      "
                      alt="logo"
                    />
                  </div>
                </div>

                <div class="d-flex align-items-center ms-3">
                  <strong class="align-baseline" style="font-size:0.95rem">{{ item.name }}</strong>
                </div>
              </div>
            </td>
            <td class="d-none d-xxl-table-cell">
              <div style="font-size:0.95rem">{{ item.domain }}</div>
            </td>
            <td class="d-none d-xl-table-cell">
              <el-tag :type="typeToTag(item.type)">{{ item.type.toUpperCase() }}</el-tag>
            </td>
            <td class="d-xl-table-cell">
              <router-link :to="`/instances/details/${item.id}`">
                <button class="btn btn-primary btn-vimeo">
                  <i class="fas fa-info fa-lg" />
                </button>
              </router-link>
            </td>
            <td class="d-xl-table-cell text-end">
              <button
                class="btn btn-danger"
                @click="
                  ;(deleteInstanceDialogVisible = true),
                    (handleUpdateInsID(item.id)),
                    (curInstanceDomain = item.domain)
                "
              >
                <i class="fas fa-trash fa-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- pagination -->
      <nav>
        <div class="row">
          <div class="col-sm-12 col-md-5">
            <span
              >Show {{ lowerBound }} to {{ upperBound }} of {{ instances.length }} Instances</span
            >
          </div>
          <div class="col-sm-12 col-md-7">
            <ul class="pagination justify-content-end me-3">
              <li class="page-item" :class="selectedPage === 1 ? 'disabled' : ''">
                <a class="page-link border-0" v-on:click="handlePagination(-1)">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>

              <li
                v-for="page in paginationIndexes"
                :key="page"
                class="page-item"
                :class="isSelected(page) ? 'active' : ''"
              >
                <a class="page-link border-0" v-on:click="handlePaginationIndex(page)">
                  {{ page }}
                </a>
              </li>

              <li
                class="page-item"
                :class="selectedPage === paginationIndexes.length ? 'disabled' : ''"
              >
                <a class="page-link border-0" v-on:click="handlePagination(1)">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </main>

  <!-- Delete Dialog -->
  <el-dialog
    title="Disable current instance"
    v-model="deleteInstanceDialogVisible"
    width="50%"
    center
  >
    <h5>Are you sure you want to remove this instance? The operation can not be undone.</h5>
    <h5 class="text-muted">Please type the instance domain:</h5>
    <el-alert
      v-if="alert.message"
      :title="alert.message"
      :type="alert.type"
      :closable="false"
      show-icon
      class="my-1"
    />
    <el-input v-model="deleteConfirmation"></el-input>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="deleteInstanceDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="handleRemove">Confirm</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'InstancesTable',
    props: {
      instances: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        nameSortIcon: 0,
        domainSortIcon: 0,
        typeSortIcon: 0,
        selectedPage: 1,
        pageSize: 10,
        pageOptions: [
          { type: '10 instances', value: 10 },
          { type: '20 instances', value: 20 },
          { type: '50 instances', value: 50 },
        ],
        curInstanceDomain: undefined,
        curInstanceID: undefined,
        deleteInstanceDialogVisible: false,
        deleteConfirmation: '',
        alert: {
          message: '',
          type: '',
        },
      }
    },
    computed: {
      upperBound() {
        const res = this.selectedPage * this.pageSize
        return res > this.instances.length ? this.instances.length : res
      },
      lowerBound() {
        return (this.selectedPage - 1) * this.pageSize + 1
      },
      tableData() {
        /* check whether User ask to sort or not */
        /* pass the param you want to sort, and descending or ascending (-1 or 1) */
        if (this.nameSortIcon % 3 === 1) return this.sortedData('name', 1)
        else if (this.nameSortIcon % 3 === 2) return this.sortedData('name', -1)
        if (this.domainSortIcon % 3 === 1) return this.sortedData('domain', 1)
        else if (this.domainSortIcon % 3 === 2) return this.sortedData('domain', -1)
        if (this.typeSortIcon % 3 === 1) return this.sortedData('type', 1)
        else if (this.typeSortIcon % 3 === 2) return this.sortedData('type', -1)
        return this.instances
      },
      paginatedTableData() {
        const initialIdx = this.pageSize * (this.selectedPage - 1)
        return this.tableData.slice(initialIdx, initialIdx + this.pageSize)
      },
      paginationIndexes() {
        const pages = Math.ceil(this.tableData.length / this.pageSize)
        let page_arr = []
        for (let i = 1; i <= pages; i++) {
          page_arr.push(i)
        }
        return page_arr
      },
    },
    methods: {
      handleSortIconClick(col) {
        switch (col) {
          case 'name':
            this.nameSortIcon++
            break
          case 'domain':
            this.domainSortIcon++
            break
          default:
            this.typeSortIcon++
        }
        this.resetSortIconValue(col)
      },
      resetSortIconValue(col) {
        if (col !== 'name') this.nameSortIcon = 0
        if (col !== 'domain') this.domainSortIcon = 0
        if (col !== 'type') this.typeSortIcon = 0
      },
      sortedData(param, sign) {
        let res = this.instances
        return res.sort((a, b) => {
          /* default is name */
          let paramA = a.name.toUpperCase() // ignore upper and lowercase
          let paramB = b.name.toUpperCase() // ignore upper and lowercase
          if (param === 'domain') {
            paramA = a.domain.toUpperCase() // ignore upper and lowercase
            paramB = b.domain.toUpperCase() // ignore upper and lowercase
          }
          if (param === 'type') {
            paramA = a.type.toUpperCase() // ignore upper and lowercase
            paramB = b.type.toUpperCase() // ignore upper and lowercase
          }
          if (paramA < paramB) {
            return -1 * sign
          }
          if (paramA > paramB) {
            return 1 * sign
          }
          // if instance param value are same
          return 0
        })
      },
      handlePagination(selectedPage) {
        this.selectedPage += selectedPage
      },
      handlePaginationIndex(selectedPage) {
        this.selectedPage = selectedPage
      },
      isSelected(pageIndex) {
        return pageIndex === this.selectedPage
      },
      typeToTag(type) {
        switch (type) {
          case 'customer':
            return 'success'
          case 'partner':
            return 'warning'
          default:
            return 'info'
        }
      },
      async deleteInstance() {
        try {
          console.log('disabling instance with id ' + this.curInstanceID)
          await this.$services.docqInstances.removeInstance(this.curInstanceID)
        } catch (error) {
          console.error(error)
        }
      },
      handleDelete() {
        this.deleteInstance()
      },
      handleRemove() {
        if (this.deleteConfirmation === this.curInstanceDomain) {
          this.deleteInstanceDialogVisible = false
          this.handleDelete()
        } else {
          this.alert.message = 'Please type the confirmation'
          this.alert.type = 'error'
        }
        this.deleteConfirmation = ''
      },
      handleUpdateInsID(id) {
        this.curInstanceID = id
      },
    },
    watch: {
      instances() {
        this.selectedPage = 1
      },
      pageSize() {
        this.selectedPage = 1
      },
      deleteInstanceDialogVisible(newVal) {
        if (!newVal) {
          this.alert.message = ''
          this.alert.type = ''
          this.deleteConfirmation = ''
        }
      },
    },
  }
</script>

<style scoped>
  .logo-img {
    width: auto;
    height: auto;
    max-width: 40px;
    max-height: 40px;
  }
  ul {
    margin-bottom: 16px !important;
  }
</style>
