<template>
  <div class="col-md-5 col-xl-4">
    <div class="card mb-3">
      <div class="card-header">
        <h5 class="card-title mb-0">Info</h5>
      </div>
      <div v-if="instanceInfo !== undefined">
        <div class="card-body text-center">
          <img
            :src="
              `https://ndmglobal.sfo2.digitaloceanspaces.com/DocQ_email/${instanceInfo.domain}.png`
            "
            alt="logo"
            class="img-fluid mb-2"
            width="128"
            height="128"
          />
          <h5 class="card-title mb-0">{{ instanceInfo.name }}</h5>
          <div class="text-muted mb-2">{{ instanceInfo.domain }}</div>
          <div :class="`badge bg-${typeToTag(instanceInfo.type)} mb-4`" style="font-size: 1em">
            {{ instanceInfo.type.toUpperCase() }}
          </div>
          <div>
            <!--            <button class="btn btn-primary btn-sm m-1">Config</button>-->
            <button class="btn btn-primary btn-sm m-1" v-on:click="launch">Launch</button>
          </div>
        </div>
        <hr class="my-0" v-if="instanceState !== undefined" />
        <div class="card-body" v-if="instanceState !== undefined">
          <h5 class="h6 card-title">Activities</h5>
          <ul class="list-unstyled mb-0">
            <li class="d-flex justify-content-between mb-1">
              Created at:
              <div class="badge bg-primary me-1 my-1">
                {{ createdDate }}
              </div>
            </li>
            <li class="d-flex justify-content-between mb-1">
              Updated at:
              <div class="badge bg-primary me-1 my-1">
                {{ updatedDate }}
              </div>
            </li>
            <li class="d-flex justify-content-between mb-1">
              UI Build Date:
              <div class="badge bg-primary me-1 my-1">
                {{ uiBuildDate }}
              </div>
            </li>
          </ul>
        </div>
        <hr class="my-0" v-if="instanceState !== undefined" />
        <div class="card-body" v-if="instanceState !== undefined">
          <h5 class="h6 card-title">Configs</h5>
          <ul class="list-unstyled mb-0">
            <li class="d-flex justify-content-between mb-1">
              <div>
                <i class="fas fa-server"></i>
                API Version:
              </div>
              <div class="badge bg-primary me-1 my-1" v-if="instanceState.versions.backend">
                {{ instanceState.versions.backend.api_version }}
              </div>
              <div class="badge bg-danger me-1 my-1" v-else>ERROR</div>
            </li>
            <li class="d-flex justify-content-between mb-1">
              <div>
                <i class="fas fa-database"></i>
                DB Version:
              </div>
              <div class="badge bg-primary me-1 my-1" v-if="instanceState.versions.backend">
                {{ instanceState.versions.backend.db_version }}
              </div>
              <div class="badge bg-danger me-1 my-1" v-else>ERROR</div>
            </li>
            <li class="d-flex justify-content-between mb-1">
              <div>
                <i class="fab fa-uikit"></i>
                UI Version:
              </div>
              <div class="badge bg-primary me-1 my-1" v-if="instanceState.versions.frontend">
                {{ instanceState.versions.frontend.version }}
              </div>
              <div class="badge bg-danger me-1 my-1" v-else>ERROR</div>
            </li>
            <li class="mb-1"></li>
          </ul>
        </div>
        <hr class="my-0" />
        <div class="card-body">
          <h5 class="h6 card-title">Droplet</h5>
          <ul class="list-unstyled mb-0">
            <li class="d-flex justify-content-between mb-1">
              <div>
                <i class="bi bi-house-door-fill"></i>
                Name:
              </div>
              <div class="badge bg-primary me-1 my-1">
                {{ instanceInfo.droplet.name }}
              </div>
            </li>
            <li class="d-flex justify-content-between mb-1">
              <div>
                <i class="fas fa-id-card"></i>
                Reference ID:
              </div>
              <div class="badge bg-primary me-1 my-1">
                {{ instanceInfo.droplet.reference_id }}
              </div>
            </li>
            <li class="d-flex justify-content-between mb-1">
              <div>
                <i class="bi bi-tags-fill"></i>
                Tags:
              </div>
              <div>
                <div
                  class="badge bg-primary me-1 my-1"
                  v-for="tag in instanceInfo.droplet.tags"
                  :key="tag"
                >
                  {{ tag }}
                </div>
              </div>
            </li>
          </ul>
        </div>
        <hr class="my-0" v-if="instanceState !== undefined" />
        <div class="card-body" v-if="instanceState !== undefined">
          <h5 class="h6 card-title">Release Name:</h5>
          <div v-if="instanceState !== undefined">
            <ul class="list-unstyled mb-0">
              <li class="mb-1">
                <div class="badge bg-primary me-1 my-1" v-if="instanceState.versions.backend">
                  <span class="fas fa-globe fa-fw me-1"></span
                  >{{ instanceState.versions.backend.release_name }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'InstancesDetailsProfile',
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      state: undefined,
      info: undefined,
    },
    data() {
      return {
        instanceInfo: undefined,
        instanceState: undefined,
      }
    },
    methods: {
      launch() {
        window.open(`https://${this.instanceInfo.domain}`, '_blank')
      },
      typeToTag(type) {
        switch (type) {
          case 'customer':
            return 'success'
          case 'partner':
            return 'warning'
          default:
            return 'secondary'
        }
      },
    },
    computed: {
      createdDate() {
        if (this.instanceState !== undefined) {
          return (
            this.$common.convertTime(this.instanceState.created_at.substr(11, 5)) +
            ', ' +
            this.instanceState.updated_at.substr(0, 10)
          )
        }
        return 'Unknown'
      },
      updatedDate() {
        if (this.instanceState !== undefined) {
          return (
            this.$common.convertTime(this.instanceState.updated_at.substr(11, 5)) +
            ', ' +
            this.instanceState.updated_at.substr(0, 10)
          )
        }
        return 'Unknown'
      },
      uiBuildDate() {
        if (this.instanceState !== undefined) {
          return (
            this.$common.convertTime(
              this.instanceState.versions.frontend.build_date.substr(11, 5),
            ) +
            ', ' +
            this.instanceState.updated_at.substr(0, 10)
          )
        }
        return 'Unknown'
      },
    },
    watch: {
      info(newVal) {
        this.instanceInfo = newVal
      },
      state(newVal) {
        this.instanceState = newVal
      },
    },
  }
</script>

<style scoped></style>
