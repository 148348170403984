<template>
  <div class="row mb-2 mb-xl-3">
    <div class="col-auto d-none d-sm-block">
      <h3 class="h3 mb-3">Instances Details</h3>
    </div>
    <div class="row">
      <!--    Rollback  Status    -->
      <el-form-item>
        <div v-if:="hasPermission">
          <el-button
            class="d-inline mr-2"
            style="min-width:180px;font-size:14px"
            icon="el-icon-s-release"
            @click="rollbackStatus"
          >
            Rollback Status
          </el-button>
          <el-button
            class="d-inline mr-2"
            icon="el-icon-delete"
            type="danger"
            style="min-width:180px;font-size:14px;margin-left:5px;"
            @click="deleteInstanceDialogVisible = true"
          >
            Disable
          </el-button>

          <el-upload
            action="#"
            accept=".png"
            class="d-inline mr-2"
            style="margin-left:5px;"
            :show-file-list="false"
            :auto-upload="false"
            :on-change="onLoginImageChange"
          >
            <el-button type="primary" icon="el-icon-upload">Upload login image</el-button>
          </el-upload>

          <el-upload
            action="#"
            accept=".png"
            class="d-inline mr-2"
            style="margin-left:5px;"
            :show-file-list="false"
            :auto-upload="false"
            :on-change="onEmailImageChange"
          >
            <el-button type="warning" icon="el-icon-s-comment">Upload email image</el-button>
          </el-upload>
        </div>
      </el-form-item>
      <!--   instance info/state  -->
      <InstancesDetailsProfile :info="info" :state="state" :loading="loading" />
      <!--health check and errors -->
      <InstancesDetailsHealth :state="state" :loading="loading" :usage="usage" />
    </div>
  </div>
  <!-- Delete Dialog -->
  <el-dialog
    title="Disable current instance"
    v-model="deleteInstanceDialogVisible"
    width="50%"
    center
  >
    <h5>Are you sure you want to remove this instance? The operation can not be undone.</h5>
    <h5 class="text-muted">Please type the instance domain:</h5>
    <el-alert
      v-if="alert.message"
      :title="alert.message"
      :type="alert.type"
      :closable="false"
      show-icon
      class="my-1"
    />
    <el-input v-model="deleteConfirmation"></el-input>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="deleteInstanceDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="handleRemove">Confirm</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
  import InstancesDetailsProfile from '@/views/Instances/InstancesDetailsProfile'
  import InstancesDetailsHealth from '@/views/Instances/InstancesDetailsHealth'
  export default {
    name: 'InstancesDetails',
    components: {
      InstancesDetailsProfile,
      InstancesDetailsHealth,
    },
    data() {
      return {
        loading: false,
        instanceState: undefined,
        instanceInfo: undefined,
        instanceUsage: undefined,
        deleteInstanceDialogVisible: false,
        deleteConfirmation: '',
        alert: {
          message: '',
          type: '',
        },
        hasPermission: false,
      }
    },
    computed: {
      info() {
        return this.instanceInfo
      },
      state() {
        return this.instanceState
      },
      usage() {
        return this.instanceUsage
      },
      isLoaded() {
        return !!this.instanceInfo
      },
    },
    methods: {
      async validatePermission() {
        try {
          this.hasPermission = await this.$services.users.validate('MANAGE_DOCQ_INSTANCES')
        } catch (err) {
          console.log(err)
        }
      },
      async loadState() {
        try {
          this.loading = true
          const { id } = this.$route.params
          this.instanceState = await this.$services.docqInstances.getState(id)
          if (!this.instanceState) {
            this.$message({
              type: 'error',
              message: 'DocQ Instance State not found',
            })
          }
        } catch (err) {
          console.error(err)
        } finally {
          this.loading = false
        }
      },
      async loadInfo() {
        try {
          this.loading = true
          const { id } = this.$route.params
          this.instanceInfo = await this.$services.docqInstances.show(id)
          if (!this.instanceInfo) {
            this.$message({
              type: 'error',
              message: 'DocQ Instance not found',
            })
            await this.$router.push('/instances')
          }
        } catch (error) {
          console.error(error)
        } finally {
          this.loading = false
        }
      },
      async loadInstanceUsage() {
        try {
          this.loading = true
          const { id } = this.$route.params
          this.instanceUsage = await this.$services.docqInstances.getInstanceUsage(id)
          if (!this.instanceUsage) {
            this.$message({
              type: 'error',
              message: 'DocQ Instance Usage data not found',
            })
          }
        } catch (err) {
          console.error(err)
        } finally {
          this.loading = false
        }
      },
      async rollbackStatus() {
        try {
          this.loading = true
          await this.$services.docqInstances.rollbackStatus(this.instanceInfo.id)

          this.$message({ message: 'Trying to rollback instance configuration.' })
        } finally {
          this.loading = false
        }
      },
      async deleteInstance() {
        try {
          this.loading = true
          const { id } = this.$route.params
          await this.$services.docqInstances.removeInstance(id)
        } catch (error) {
          console.error(error)
        } finally {
          this.loading = false
          await this.$router.push('/instances')
        }
      },
      handleDelete() {
        this.deleteInstance()
      },
      handleRemove() {
        if (this.deleteConfirmation === this.instanceInfo.domain) {
          this.deleteInstanceDialogVisible = false
          this.handleDelete()
        } else {
          this.alert.message = 'Please type the confirmation'
          this.alert.type = 'error'
        }
        this.deleteConfirmation = ''
      },
      onLoginImageChange(event) {
        this.changeImage(event, 'login')
      },
      onEmailImageChange(event) {
        this.changeImage(event, 'email')
      },
      async changeImage(event, type) {
        try {
          await this.$confirm(`Are you sure to upload this image?`, 'Upload image?', {
            center: true,
            confirmButtonText: 'Confirm',
          })

          const file = event.raw
          await this.$api.uploadImage(this.instance, type, file)
        } catch (error) {
          console.log(error)
        }
      },
    },
    mounted() {
      this.loadInfo()
      this.loadState()
      this.loadInstanceUsage()
      this.validatePermission()
    },
    watch: {
      deleteParentDialogVisible(newVal) {
        if (!newVal) {
          this.alert.message = ''
          this.alert.type = ''
          this.deleteConfirmation = ''
        }
      },
    },
  }
</script>

<style scoped></style>
