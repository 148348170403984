<template>
  <div class="row mb-0 mb-xl-1">
    <div class="col-auto d-none d-sm-block">
      <h3><strong>Instances</strong></h3>
      <span class="text-muted">Create or update DocQ Instances and manage their settings.</span>
    </div>

    <div class="col-auto ms-auto text-end mt-n1">
      <div class="row">
        <div class="col-sm-6 col-xl-auto p-1">
          <el-input
            v-model="filters.query"
            clearable
            placeholder="Search"
            prefix-icon="fas fa-search"
            class="w-100 card"
            style="max-width: 2100px;"
          />
        </div>
        <div class="col-sm-6 col-xl-auto p-1">
          <el-select
            v-model="filters.types"
            clearable
            placeholder="Types"
            multiple
            collapse-tags
            class="w-100 card"
          >
            <el-option
              v-for="type in types"
              :key="type.key"
              :label="type.label"
              :value="type.key"
            />
          </el-select>
        </div>
        <div class="col-sm-6 col-xl-auto p-1">
          <el-select
            v-model="activeFilters.actives"
            clearable
            placeholder="Status"
            multiple
            collapse-tags
            class="w-100 card"
          >
            <el-option
              v-for="stat in status"
              :key="stat.key"
              :label="stat.label"
              :value="stat.key"
            />
          </el-select>
        </div>
        <div class="col-sm-6 col-xl-auto p-1">
          <router-link to="/instances/create">
            <el-button v-if:="hasPermission" type="primary" class="w-100">New Instance</el-button>
          </router-link>
        </div>
        <!--        <div class="col-sm-6 col-xl-3 p-1">-->
        <!--          <router-link to="/instances/create">-->
        <!--            <el-button v-if="true" type="primary" plain class="w-100">New Instance</el-button>-->
        <!--          </router-link>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
  <InstancesTable :loading="false" :instances="filteredInstances" />
</template>

<script>
  import InstancesTable from '@/views/Instances/InstancesTable'
  export default {
    name: 'Instances',
    components: { InstancesTable },
    data() {
      return {
        instancesList: [],
        loading: false,
        hasPermission: false,
        filters: {
          query: '',
          types: [],
        },
        activeFilters: {
          actives: ['active'],
        },
        types: [
          {
            key: 'customer',
            label: 'Customer',
          },
          {
            key: 'internal',
            label: 'Internal',
          },
          {
            key: 'partner',
            label: 'Partner',
          },
        ],
        status: [
          {
            key: 'active',
            label: 'active',
          },
          {
            key: 'inactive',
            label: 'inactive',
          },
        ],
      }
    },
    computed: {
      filteredInstances() {
        const { query = '', types = [] } = this.filters
        const { actives = [] } = this.activeFilters
        let booleans = []
        if (actives.length) {
          for (let i = 0; i < actives.length; i++) {
            if (actives[i] == 'active') {
              booleans[i] = true
            }
            if (actives[i] == 'inactive') {
              booleans[i] = false
            }
          }
        }
        const sanitizedQuery = query.trim().toLowerCase()
        return this.instancesList.filter(({ name, domain, type, active }) => {
          return (
            [name, domain].some(val => val.toLowerCase().includes(sanitizedQuery)) &&
            (!types.length || types.includes(type)) &&
            (!booleans.length || booleans.includes(active))
          )
        })
      },
    },
    methods: {
      async validatePermission() {
        try {
          this.hasPermission = await this.$services.users.validate('MANAGE_DOCQ_INSTANCES')
        } catch (err) {
          console.log(err)
        }
      },
      async loadData() {
        try {
          this.loading = true
          this.instancesList = await this.$services.docqInstances.get()
        } catch (error) {
          // use element-ui notification to show error message
        } finally {
          this.loading = false
        }
      },
    },
    mounted() {
      this.loadData()
      this.validatePermission()
    },
  }
</script>

<style>
  .el-input__prefix {
    margin-left: 7px !important;
  }
</style>
