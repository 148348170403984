<template>
  <h1 class="h3 mb-3"><strong>Create</strong> Instance</h1>
  <div class="card bg-white mt-5 mb-5">
    <div class="row px-4">
      <div class="col-md-12">
        <div class="p-3 py-2">
          <el-form
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            class="ruleForm"
            label-width="120px"
          >
            <div class="row mt-1">
              <h4 class="text-right">
                <strong><i class="fas fa-address-card"></i> Info</strong>
              </h4>
              <hr />
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <el-form-item prop="domain">
                    <label class="labels">
                      <strong>* Domain (Ex. your_favorite.docq.app)</strong>
                    </label>
                    <el-input v-model="ruleForm.domain"></el-input>
                  </el-form-item>
                </div>
                <div class="col-md-3"></div>
              </div>
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <el-form-item prop="name">
                    <label class="labels"><strong>* Name</strong></label>
                    <el-input v-model="ruleForm.name"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <el-form-item prop="type">
                    <label class="labels"><strong>* Type</strong></label>
                    <el-select
                      v-model="ruleForm.type"
                      class="w-100"
                      clearable
                      filterable
                      placeholder="Select"
                    >
                      <el-option v-for="type in types" :key="type" :label="type" :value="type">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="col-md-3"></div>
              </div>
            </div>
            <div class="row mt-1">
              <h4 class="text-right" style="font-style:oblique">
                <strong><i class="fas fa-users-cog"></i> Configuration</strong>
              </h4>
              <hr />
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <el-form-item prop="modules">
                    <label class="labels"><strong>* Modules</strong></label>
                    <el-select
                      v-model="ruleForm.modulesProxy"
                      class="w-100"
                      clearable
                      collapse-tags
                      filterable
                      multiple
                      placeholder="Select modules"
                    >
                      <el-option
                        v-for="module in modules"
                        :key="module.id"
                        :label="module.name"
                        :value="module.id"
                      >
                        {{ module.name }}
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-3">
                  <el-form-item prop="users">
                    <label class="labels"><strong>* Limit Users</strong></label>
                    <el-input-number
                      id="users"
                      v-model="ruleForm.users"
                      :min="1"
                      class="w-100"
                      controls-position="right"
                    />
                  </el-form-item>
                </div>
                <div class="col-md-3">
                  <el-form-item prop="workflows">
                    <label class="labels">Limit Workflows</label>
                    <el-input-number
                      id="workflows"
                      v-model="ruleForm.workflows"
                      :min="1"
                      class="w-100"
                      controls-position="right"
                    />
                  </el-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-3">
                  <el-form-item prop="docsMonthly">
                    <label class="labels">Limit Docs Monthly</label>
                    <el-input-number
                      id="docsMonthly"
                      v-model="ruleForm.docsMonthly"
                      :min="1"
                      class="w-100"
                      controls-position="right"
                    />
                  </el-form-item>
                </div>
                <div class="col-md-3">
                  <el-form-item prop="docsYearly">
                    <label class="labels">Limit Docs Yearly</label>
                    <el-input-number
                      id="docsYearly"
                      v-model="ruleForm.docsYearly"
                      :min="1"
                      class="w-100"
                      controls-position="right"
                    />
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="row mt-1">
              <h4 class="text-right" style="font-style:oblique">
                <strong><i class="fas fa-network-wired"></i> Droplet</strong>
              </h4>
              <hr />
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <el-form-item prop="droplet">
                    <div class="row">
                      <div class="col-4 pe-0"></div>
                      <div class="row"></div>
                      <div class="col-12">
                        <label class="text-muted" for="dropletOption"
                          >Create or select an existing droplet?</label
                        >
                        <h4 />
                        <el-radio-group
                          id="dropletOption"
                          v-model="ruleForm.dropletOption"
                          @change="handleDropletOptionChange"
                        >
                          <el-radio-button label="0">Create</el-radio-button>
                          <el-radio-button label="1">Select</el-radio-button>
                        </el-radio-group>
                        <h3 />
                      </div>
                    </div>
                    <div class="row">
                      <div class="mb-1">
                        <label class="labels"><strong>* Droplet</strong></label>
                      </div>
                      <div class="col-8">
                        <el-input
                          v-if="ruleForm.dropletOption === '0'"
                          id="droplet"
                          v-model="ruleForm.droplet"
                          placeholder="Choose a name for the droplet"
                        />
                        <el-select
                          v-else
                          id="droplet"
                          v-model="ruleForm.droplet"
                          allow-create
                          class="w-100"
                          filterable
                          placeholder="Select a droplet"
                        >
                          <el-option
                            v-for="droplet in droplets"
                            :key="droplet.id"
                            :label="droplet.name"
                            :value="droplet.id"
                          >
                            <span class="float-left pr-1">{{ droplet.name }}</span>
                            <span class="text-muted float-right d-none d-sm-inline">{{
                              droplet.tags
                            }}</span>
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="row"></div>
                  </el-form-item>
                </div>
                <div class="col-md-3"></div>
              </div>
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <el-form-item prop="droplet-tag">
                    <div class="mb-1">
                      <label class="labels"
                        ><strong>Droplet Tag(s) - Default: docq, subdomain</strong></label
                      >
                      <el-select
                        v-model="ruleForm.selected_tags"
                        :reserve-keyword="false"
                        allow-create
                        class="w-100"
                        default-first-option
                        filterable
                        multiple
                        placeholder="Choose tags for your droplet - or leave as default"
                      >
                        <el-option
                          v-for="(item, item_dx) in map_tags"
                          :key="item_dx"
                          :label="item"
                          :value="item"
                        />
                      </el-select>
                    </div>
                  </el-form-item>
                </div>
                <div class="col-md-3"></div>
              </div>
              <!-- Select Project -->
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <el-form-item prop="project">
                    <label class="labels"><strong>Droplet Project</strong></label>
                    <el-select
                      v-model="ruleForm.selected_project"
                      class="w-100"
                      filterable
                      placeholder="Choose the DigitalOcean project for your droplet - or leave as default"
                    >
                      <el-option
                        v-for="project in map_projects"
                        :key="project.id"
                        :label="project.name"
                        :value="project.id"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <!-- Select Parent -->
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <el-form-item prop="parent">
                    <label class="labels">Parent Instance(Optional)</label>
                    <el-select
                      v-model="ruleForm.parent"
                      class="w-100"
                      clearable
                      filterable
                      placeholder="Select a parent"
                    >
                      <el-option
                        v-for="parent in parents"
                        :key="parent.id"
                        :label="parent.name"
                        :value="parent.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
            </div>
            <!-- Create and Reset buttons -->
            <el-form-item>
              <el-button :loading="submitting" type="primary" @click="submitForm">Create</el-button>
              <el-button @click="resetForm('ruleForm')">Reset</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const ERRORS_INITIAL = {
    name: '',
    domain: '',
    type: '',
    droplet: '',
    settings: {
      parent: '',
      users: '',
      modules: '',
      workflow: '',
      docsYearly: '',
      docsMonthly: '',
    },
  }

  export default {
    name: 'InstanceCreate',
    components: {},

    data() {
      return {
        submitting: false,
        droplets: [],
        map_tags: [],
        map_projects: [],
        types: ['Customer', 'Internal', 'Partner'],
        parents: [],
        modules: [],
        ruleForm: {
          name: '',
          domain: '',
          type: '',
          dropletOption: '0',
          droplet: '',
          selected_tags: [],
          selected_project: '',
          parent: undefined,
          users: undefined,
          modulesProxy: [],
          workflows: undefined,
          docsYearly: undefined,
          docsMonthly: undefined,
        },
        imageUrl: '',
        rules: {
          domain: [{ required: true, message: 'Please input a valid domain', trigger: 'blur' }],
          name: [
            { required: true, message: 'Please input a valid name', trigger: 'blur' },
            { min: 3, max: 9, message: 'Length should be 3 to 9', trigger: 'blur' },
            {
              regex: '^[a-zA-Z]+$',
              message: 'Please use only alphabet characters without spaces',
              trigger: 'blur',
            },
          ],
          type: [
            {
              required: true,
              message: 'Please select at least one instance type',
              trigger: 'change',
            },
          ],
          modules: [
            {
              type: 'array',
              required: true,
              message: 'Please select at least one module',
              trigger: 'blur',
            },
          ],
          users: [{ required: true, message: 'Please set the limit for users', trigger: 'blur' }],
          droplets: [{ required: true, message: 'Please set the droplet', trigger: 'blur' }],
        },
      }
    },
    methods: {
      async loadModules() {
        try {
          this.modules = await this.$services.modules.get()
        } catch (err) {
          console.log(err)
        }
      },
      async loadDroplets() {
        const droplets = await this.$services.digitalOcean.get_droplets()
        droplets.forEach(droplet => {
          this.map_tags = [
            ...this.map_tags,
            ...droplet.tags.filter(d => !this.map_tags.includes(d)),
          ]
        })
        this.droplets = droplets.map(droplet => ({ ...droplet, tags: droplet.tags.join(', ') }))
        this.map_projects = await this.$services.digitalOcean.get_projects()
      },
      async loadParent() {
        const parents = await this.$services.docqInstancesParents.getParentList()
        this.parents = parents.sort((a, b) => (a.name > b.name ? 1 : -1))
      },
      handleDropletOptionChange() {
        this.ruleForm.droplet = ''
      },
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw)
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/png'
        const isLt2M = file.size / 1024 / 1024 < 2

        if (!isJPG) {
          this.$message.error('Avatar picture must be PNG format!')
        }
        if (!isLt2M) {
          this.$message.error('Avatar picture size can not exceed 2MB!')
        }
        return isJPG && isLt2M
      },
      async submitForm() {
        try {
          this.submitting = true
          this.errors = {}
          const dto = this.createDto()
          const { id } = await this.$services.docqInstances.create(dto)
          console.log(dto)
          this.$message({
            type: 'success',
            message: `The instance was successfully created!`,
          })
          await this.$router.push(`/instances/details/${id}`)
        } catch (error) {
          if (error.data) {
            this.errors = error.data.reduce((map, { property, error }) => {
              if (property.startsWith('droplet')) {
                return { ...map, droplet: error }
              }

              return { ...map, [property]: error }
            }, ERRORS_INITIAL)
          }
        } finally {
          this.submitting = false
        }
      },
      resetForm(formName) {
        this.$refs[formName].resetFields()
      },
      createDto() {
        const {
          name,
          domain,
          type,
          dropletOption,
          droplet,
          parent,
          users,
          modulesProxy,
          workflows,
          docsYearly,
          docsMonthly,
          selected_tags,
          selected_project,
        } = this.ruleForm
        const dropletProperty = dropletOption === '0' ? 'name' : 'id'
        let modules = modulesProxy.map(item => {
          return item
        })
        return {
          name,
          domain,
          type,
          droplet: {
            [dropletProperty]: droplet,
          },
          selected_tags,
          selected_project,
          settings: {
            parent,
            users,
            modules,
            workflows,
            docsYearly,
            docsMonthly,
          },
        }
      },
    },
    mounted() {
      this.loadDroplets()
      this.loadParent()
      this.loadModules()
    },
  }
</script>

<style lang="scss">
  .border-right {
    border-right: 0.01em solid #d9dee2;
  }

  .bg-gray-card {
    background: none;
  }

  .ruleForm {
    .el-form-item {
      margin-bottom: 1px !important;
    }

    .el-form-item__content {
      margin-left: 0 !important;
      margin-bottom: 18px !important;
      line-height: 18px !important;
    }
  }
</style>
