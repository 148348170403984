<template>
  <div className="chart" :id="this.chart_id + '-div'">
    <canvas :id="this.chart_id"></canvas>
  </div>
</template>

<script>
  import Chart from 'chart.js'

  export default {
    name: 'DashboardDonutGraphRAM',
    data() {
      return {
        chartData: null,
        chart_label: [],
        chart_data: [],
      }
    },
    props: {
      usage_data: {
        type: Object,
        required: true,
      },
      chart_id: {
        type: String,
        required: true,
      },
    },
    methods: {
      initializeData() {
        // Deleting variables that are not needed in the display
        delete this.usage_data.total
        delete this.usage_data.shared
        this.chartData = {
          type: 'pie',
          data: {
            labels: Object.keys(this.usage_data),
            datasets: [
              {
                data: Object.values(this.usage_data),
                backgroundColor: ['#1cbb8c', '#3b7ddd', '#e1f150', '#f14949'], //Chart colors are defined here
                borderWidth: 1,
                borderColor: 'white',
              },
            ],
          },
          options: {
            responsive: !window.MSInputMethodContext,
            maintainAspectRatio: true,
            legend: {
              display: true,
              position: 'left',
            },
            cutoutPercentage: 68,
            hoverOffset: 4,
          },
        }
      },
      createGraph() {
        this.initializeData()
        document.getElementById(this.chart_id).remove()
        document.getElementById(this.chart_id + '-div').innerHTML =
          '<canvas id="' + this.chart_id + '" />'
        const ctx = document.getElementById(this.chart_id)
        new Chart(ctx, this.chartData)
      },
    },
    mounted() {
      this.createGraph()
    },
  }
</script>
<style scoped></style>
