<template>
  <div class="col-md-7 col-xl-8">
    <!-- instance usage section -->
    <div v-if="usage !== undefined" class="row g-4 mb-4">
      <div class="col-12 col-md-6">
        <div class="card m-0">
          <div class="card-body w-75">
            <h5 class="card-title">RAM Usage</h5>
            <DashboardDonutGraph
              :usage_data="usage.ram"
              chart_id="ram"
              class="mb-2"
            ></DashboardDonutGraph>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="card m-0">
          <div class="card-body w-75">
            <h5 class="card-title">Disk Usage</h5>
            <DashboardDonutGraph
              :usage_data="usage.disk"
              chart_id="disk"
              class="mb-2"
            ></DashboardDonutGraph>
          </div>
        </div>
      </div>
    </div>
    <!-- health bar section -->
    <div v-if="instanceState !== undefined" class="card" style="max-height: 65vh">
      <div class="card-header">
        <div class="flex-grow-1">
          <small class="card-title float-end text-navy">Updated at: {{ updatedTime }}</small>
          <h5 class="card-title mb-0">Health Check</h5>
        </div>
      </div>
      <div class="card-body" style="overflow-y: scroll;">
        <div v-for="item in instanceState.healthcheck" :key="item">
          <div v-if="item.status !== 'IGNORE'" class="d-flex align-items-start">
            <h1>
              <i
                v-if="item.status === 'OK'"
                class="bi bi-check-circle me-2"
                style="color: forestgreen"
              />
              <i v-else class="bi bi-exclamation-circle me-2" style="color: red" />
            </h1>
            <div class="flex-grow-1">
              <h6 class="float-end text-navy">
                <h6 v-if="item.type !== 'module'">{{ item.domain }}</h6>
                <a
                  v-if="item.type === 'module'"
                  v-bind:href="item.domain + '/healthcheck'"
                  target="_blank"
                  >{{ item.domain }}</a
                >
              </h6>
              <strong>{{ item.service }}</strong>
              <br />
              <small class="text-muted">Type:{{ item.type }}</small>
              <small class="text-muted" v-if="item.build !== null && item.build !== undefined">
                - Update date: {{ item.build.date }} - Version: {{ item.build.version }}</small
              >
            </div>
          </div>
          <hr v-if="item.status !== 'IGNORE'" />
        </div>
      </div>
    </div>
    <!-- error section -->
    <div v-if="instanceState !== undefined" class="card" style="max-height: 40vh">
      <div class="card-header">
        <h5 class="card-title mb-0">Error</h5>
      </div>
      <div class="card-body" style="overflow-y: scroll">
        <div class="d-flex align-items-start mt-1">
          <div class="flex-grow-1">
            <div v-if="!errors" class="border text-md text-muted p-2 mb-2">
              No errors on the current instance!
              <div class="float-end badge bg-success me-1 my-1">
                OK
              </div>
            </div>
            <div v-for="error in errors" :key="error" class="border text-md text-muted p-2 mb-2">
              {{ error }}
              <div class="float-end badge bg-danger me-1 my-1">
                Failed
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DashboardDonutGraph from '../Dashboard/DashboardDonutGraph'

  export default {
    name: 'InstancesDetailsHealth',
    components: { DashboardDonutGraph },
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      state: {},
      usage: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        instanceState: undefined,
        errors: null,
        updatedTime: '',
      }
    },
    watch: {
      state(newVal) {
        this.instanceState = newVal
        if (this.instanceState) {
          if (newVal.errors) this.errors = newVal.errors.split('\n')
          this.updatedTime =
            this.$common.convertTime(newVal.updated_at.substr(11, 5)) +
            ', ' +
            newVal.updated_at.substr(0, 10)
        }
      },
    },
  }
</script>

<style></style>
